import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CardTemplate from 'react-credit-cards-2'
import { useNavigate } from 'react-router-dom'

import BorderedContainer from 'components/BorderedContainer'
import LinkButton from 'components/LinkButton'
import {
  CardIssuer,
  NewCardNumberField,
  NewValidThruField,
} from 'components/MaskedInput'
import SubmitButton from 'components/SubmitButton'
import Title from 'components/Title'

import { getSignUpProfile } from 'store/signup/selectors'

import CreditCardFormDatas, { CreditCardFormData } from 'typings/CreditCardForm'

import { joiResolver } from '@hookform/resolvers/joi'
import { useCreateParentMutation } from '../../store/api'
import { CreateParentProps } from '../../store/api/types/Event.types'
import { PageWrapper } from 'components/PageWrapper'

import 'react-credit-cards-2/dist/lib/styles.scss'
import { useForm } from 'react-hook-form'
import { FormInput } from 'components/Form/FormInput'
import { CARD_VERIFY_VALIDATOR } from './VerifyAge.utils'

const CreditCardOption: React.FC = () => {
  let navigate = useNavigate()
  const [createParent, { isLoading }] = useCreateParentMutation()

  const profile = useSelector(getSignUpProfile)

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<CreditCardFormData>({
    resolver: joiResolver(CARD_VERIFY_VALIDATOR),
    mode: 'all',
  })
  const watchFields = watch()

  const onSubmit = async (data: CreditCardFormData) => {
    if (CreditCardFormDatas.isValid(data)) {
      try {
        await createParent({
          ...profile,
          pin: `${profile.pin?.firstDigit}${profile.pin?.secondDigit}${profile.pin?.thirdDigit}${profile.pin?.fourthDigit}`,
        } as unknown as CreateParentProps).unwrap()
        navigate(`/login-requested/${profile.email?.trim().toLowerCase()}`)
      } catch (e) {
        navigate('/sign-up')
      }
    }
  }

  const [focusedField, setFocusedField] = useState<
    keyof CreditCardFormData | undefined
  >()

  const [cardIssuer, setCardIssuer] = useState<CardIssuer>()

  const getFocusedField = (
    key: keyof CreditCardFormData | undefined,
  ): 'number' | 'cvc' | 'expiry' | 'name' | undefined => {
    switch (key) {
      case 'holderName':
        return 'name'
      case 'number':
        return 'number'
      case 'validThru':
        return 'expiry'
      default:
        return undefined
    }
  }

  return (
    <PageWrapper>
      <BorderedContainer>
        <Title>Please verify that you are an adult</Title>
        <p className="dark-gray f6">
          Provide a valid credit card. <b>No charges will apply.</b>
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column items-center">
            <div className="pv4">
              <CardTemplate
                number={watchFields.number || ''}
                name={watchFields.holderName || ''}
                expiry={watchFields.validThru || ''}
                cvc={''}
                focused={getFocusedField(focusedField)}
                callback={(cardInfo) =>
                  setCardIssuer(cardInfo.issuer as CardIssuer)
                }
              />
            </div>
            <div className="mw6">
              <NewCardNumberField
                cardIssuer={cardIssuer}
                control={control}
                errorText={errors.number?.message}
                label="Card Number"
                name="number"
                onFocus={() => setFocusedField('number')}
              />
              <FormInput
                label="Cardholder Name"
                name="holderName"
                control={control}
                errorText={errors.holderName?.message}
                onFocus={() => setFocusedField('holderName')}
              />
              <NewValidThruField
                control={control}
                errorText={errors.validThru?.message}
                label="Valid Thru"
                name="validThru"
                onFocus={() => setFocusedField('validThru')}
                placeholder="07/28"
              />
            </div>
            <div className="w5 pt4 pt5-l">
              <SubmitButton className="w-100" disabled={isLoading}>
                Verify that i'm an adult
              </SubmitButton>
            </div>
            <div className="w5 pt3">
              <LinkButton className="w-100" to="/verify-age" ghost>
                Back
              </LinkButton>
            </div>
          </div>
        </form>
      </BorderedContainer>
    </PageWrapper>
  )
}

export default CreditCardOption
