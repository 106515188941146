import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import BorderedContainer from 'components/BorderedContainer'
import Button from 'components/Button'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import LinkButton from 'components/LinkButton'
import QuestionCard, { Question } from 'components/QuestionCard'
import ShadowModal from 'components/ShadowModal'
import Title from 'components/Title'

import { getSignUpProfile } from 'store/signup/selectors'
import { useCreateParentMutation } from '../../store/api'
import { CreateParentProps } from '../../store/api/types/Event.types'
import { PageWrapper } from 'components/PageWrapper'

const successQuestion = 'Right answer'
const successQuestion2 =
  'Keep going! You need to correctly answer three questions in a row to complete verification.'
const failureQuestion = 'Wrong Answer'
const failureQuestion2 =
  'You must correctly answer three questions in a row to complete verification. Please start again or choose another verification method.'

const questions: Question[] = [
  {
    description: `Question 1/3: `,
    subDescription: `Arrange the last names in alphabetical order.`,
    alternatives: [
      '1. Layman, Kenneth',
      '2. Lavoie, Rachelle',
      '3. Laviolette, Pierre',
      '4. Lawson, Virginia',
      '5. Lavigne, Margaret',
    ],
    possibleAnswers: [
      '2, 5, 1, 3, 4',
      '5, 3, 2, 4, 1',
      '2, 3, 5, 4, 1',
      '5, 2, 4, 3, 1',
      '2, 3, 5, 1, 4',
    ],
    rightAnswerIndex: 1,
  },
  {
    description: 'Question 2/3: ',
    subDescription: 'Correctly order the numbers below.',
    alternatives: ['1. 24981', '2. 24736', '3. 26483', '4. 24965', '5. 26473'],
    possibleAnswers: [
      '4, 2, 3, 5, 1',
      '2, 4, 5, 1, 3',
      '4, 5, 2, 3, 1',
      '2, 4, 1, 5, 3',
      '2, 5, 3, 4, 1',
    ],
    rightAnswerIndex: 3,
  },
  {
    description: 'Question 3/3: ',
    subDescription:
      'Last one! Arrange the dates below from the oldest to the most recent.',
    alternatives: [
      '1. June 5, 1999',
      '2. December 20, 1998',
      '3. October 15, 1997',
      '4. January 12, 1998',
      '5. September 4, 1999',
    ],
    possibleAnswers: [
      '3, 4, 2, 5, 1',
      '4, 1, 5, 3, 2',
      '3, 4, 2, 1, 5',
      '4, 3, 2, 1, 5',
      '3, 2, 4, 1, 5',
    ],
    rightAnswerIndex: 2,
  },
]

const VerifyAge: React.FC = () => {
  const navigate = useNavigate()
  const [count, setCount] = useState(0)
  const [rightAnswers, setRightAnswers] = useState(new Set())
  const profile = useSelector(getSignUpProfile)
  const [createParent, { isLoading }] = useCreateParentMutation()

  const checkAllAnswers = rightAnswers.size === questions.length

  const handleSignUp = async () => {
    try {
      await createParent({
        ...profile,
        pin: `${profile.pin?.firstDigit}${profile.pin?.secondDigit}${profile.pin?.thirdDigit}${profile.pin?.fourthDigit}`,
      } as unknown as CreateParentProps).unwrap()
      setModalMessage(null)
      navigate(`/login-requested/${profile.email?.trim().toLowerCase()}`)
    } catch (e) {
      setModalMessage(null)
      navigate('/sign-up')
    }
  }
  const handleOkClick = async () => {
    if (checkAllAnswers) {
      await handleSignUp()
    } else {
      setModalMessage(null)
    }
  }

  const [modalMessage, setModalMessage] = useState<string | null>(null)
  const [modalMessage2, setModalMessage2] = useState<string | null>(null)

  return (
    <PageWrapper>
      <BorderedContainer>
        <Title>Verify you are an adult</Title>
        {questions.map((question, index) => (
          <div key={index}>
            <QuestionCard
              question={question}
              shownQuestionIndex={count % questions.length}
              onRightAnswer={() => {
                setModalMessage(`${successQuestion}`)
                setModalMessage2(`${successQuestion2}`)
                setRightAnswers(rightAnswers.add(question.rightAnswerIndex))
                setCount((count) => count + 1)
              }}
              questionIndex={index}
              onClick={() => {
                setModalMessage(`${failureQuestion}`)
                setModalMessage2(`${failureQuestion2}`)
                setCount(0)
              }}
            />
          </div>
        ))}
        <div className="w-100 pt2">
          <LinkButton className="mw5 w-100" to="/verify-age" ghost>
            Back
          </LinkButton>
        </div>
      </BorderedContainer>
      <ImageDecoration
        type={ImageDecorationType.GIRL_RUNNING}
        position="left"
      ></ImageDecoration>

      <ShadowModal isModalOpen={!!modalMessage} isLarge={true}>
        <div className="flex justify-center nunito-regular f3 pt4 dark-gray">
          {isLoading ? 'Please, wait' : modalMessage}
        </div>
        <div className="flex justify-left nunito-regular f3 pt4 dark-gray">
          {isLoading ? 'Updating information' : modalMessage2}
        </div>
        <div className="flex justify-center pt5">
          <Button
            ghost
            theme="secondary"
            onClick={handleOkClick}
            disabled={isLoading}
          >
            {isLoading ? 'Loading' : 'OK'}
          </Button>
        </div>
      </ShadowModal>
    </PageWrapper>
  )
}

export default VerifyAge
