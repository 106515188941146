import React from 'react'
import { useParams } from 'react-router'

import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import { Page } from 'components/Page'

type MagicLinkRequestedParams = {
  email: string
}

const MagicLinkRequested: React.FC = () => {
  const { email } = useParams<MagicLinkRequestedParams>()

  return (
    <Page>
      <BorderedContainer>
        <Title>Log In</Title>
        <div className="flex justify-center">
          <img
            style={{ maxWidth: 300 }}
            aria-hidden="true"
            src="/assets/login-successfully.png"
            alt=""
          />
        </div>
        <p className="dark-gray f5 tc pt3">
          A magic link to access your account was sent to you if you have an
          account registered with us. Please check your e-mail.
        </p>
        <h3 className="dark-gray f4 lh-copy tc">{email}</h3>
      </BorderedContainer>
    </Page>
  )
}

export default MagicLinkRequested
